import React from "react";

import "../css/footer.css";

import Logo from "../images/logof.png";
import IG from "../images/ig.svg";
import TW from "../images/tw.svg";
import LI from "../images/li.svg";
import FB from "../images/fb.svg";
import PH from "../images/tel_footer.svg";
import ML from "../images/mail_footer.svg";
import PL from "../images/place.svg";

var d = new Date();
var date = d.getFullYear();

function Footer() {
  return (
    <footer>
      {/* <div className="footerContentContainer">
        <div className="footerContentLeft">
          <div className="footerContentFrontRow">
            <img src={Logo} alt="jct-logo" style={{ width: "6rem" }} />
          </div>
          <div className="footerContentSecondRow">
            <h1>Stay in touch</h1>
            <ul className="footerIconDiv">
              <li>
                <a href="https://instagram.com/jangraciviltech" target="#">
                  <img src={IG} alt="instagram logo" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/jangraciviltech" target="#">
                  <img src={TW} alt="twitter logo" />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/jangraciviltech" target="#">
                  <img src={FB} alt="facebook logo" />
                </a>
              </li>
              <li>
                <a
                  href="https://linkedin.com/company/jangraciviltech"
                  target="#"
                >
                  <img src={LI} alt="linkedin logo" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footerContentCenter">
          <h1>About Us</h1>
          <ul>
            <li>Help</li>
            <li>Careers</li>
            <li>Privacy Policy</li>
          </ul>
        </div>
        <div className="footerContentCenter">
          <h1>Contact Us</h1>
          <ul>
            <li style={{alignContent:"start"}}>
              <img src={PL} alt="places" />
              <a href="https://g.page/jangra-civil-tech?share" target="#">
                #2912, Sec- 9/11 Hisar, Haryana (125005)
              </a>
            </li>
            <li>
              <img src={ML} alt="mail icon"/>
              <a href="mailto:jangra.survey@gmail.com">
                jangra.survey@gmail.com
              </a>
            </li>
            <li>
              <img src={PH} alt="telephone" />
              <a href="tel:">156516156</a>
            </li>
          </ul>
        </div>
        <div className="footerContentRight">
          <h1>Newsletter</h1>
          <form>
            <input
              type="email"
              className=""
              placeholder="Enter your email"
            ></input>
            <button>Subscribe</button>
          </form>
        </div>
      </div> */}

      <hr></hr>
      <div className="footerContainer">
        <div className="footerDiv">
          <p>Copyright ©{date} Jangra Civil Tech | All rights reserved</p>
          <p>
            Developed by{" "}
            <a href="https://quadqode.com" target="#">
              quadqode
            </a> | <a href="/eodb">EODB</a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
