import React from "react";
import "../css/card.css";

import Logo from "../images/jct_logo.svg";
import PH from "../images/card_ph.svg";
import ML from "../images/card_ml.svg";
import PL from "../images/card_pl.svg";

function Card() {
  return (
    <div className="cardContainer">
      <div className="cardDiv">
        <div className="cardTop">
          <img src={Logo} alt="logo" />
          <h1>Jangra Civil Tech</h1>
          <p>Land Surveyor & Project Consulting Engineers</p>
        </div>
        <div className="cardBottom">
          <a
            href="https://www.google.com/maps/dir//Jangra+Civil+Tech,+2912,+Sector+9-11,+New+Model+Town+Exyension,+Sector+11,+Hisar,+Haryana+125005/@29.1245414,75.74528,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x39123308553fb109:0xca2b8bf065034528!2m2!1d75.7474687!2d29.1245414!3e0"
            target="#"
          >
            <label>
              <img src={PL} alt="place icon" />
              #2912, Sec- 9/11, Hisar(125005)
            </label>
          </a>
          <a href="mailto:mail@jangraciviltech.com?subject=From website" target="#">
            <label>
              <img src={ML} alt="mail icon" />
              mail@jangraciviltech.com
            </label>
          </a>
          <a href="tel:+919992220461" target="#">
            <label>
              <img src={PH} alt="call icon" />
              9992220461
            </label>
          </a>
          <form className="cardForm">
            <input type="email" placeholder="Enter your mail"></input>
            <button>NOTIFY ME</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Card;
