import React from "react";

function Eodb() {
  return (
    <iframe
      src="https://hsac.org.in/eodb/"
      width="100%"
      height="100%"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        border: "none",
      }}
      allow="geolocation"
    ></iframe>
  );
}

export default Eodb;
