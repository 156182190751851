import React from "react";
import "../css/update.css";

import IG from "../images/ig.svg";
import TW from "../images/tw.svg";
import LI from "../images/li.svg";
import FB from "../images/fb.svg";
import PH from "../images/tel.svg";
import ML from "../images/mail.svg";


function Updates() {
  return (
    <div className="updatesContainer">
      <div className="updatesDiv">
        <div className="socialIconsDiv">
          <a href="https://instagram.com/jangraciviltech" target="#"><img src={IG} alt="instagram logo" /></a>
          <a href="https://twitter.com/jangraciviltech" target="#"><img src={TW} alt="twitter"/></a>
          <a href="https://facebook.com/jangraciviltech" target="#"><img src={FB} alt="facebook"/></a>
          <a href="https://linkedin.com/company/jangraciviltech" target="#"><img src={LI} alt="linkedin"/></a>
        </div>
        <div className="contactsDiv">
          <a href="mailto:jangra.survey@gmail.com"><img src={ML} alt="mail"/></a>
          <a href="tel:"><img src={PH} alt="telephone"/></a>
        </div>
      </div>
    </div>
  );
}

export default Updates;
