import React from "react";
import logo from "./logo.svg";
import { BrowserRouter, Route } from "react-router-dom";

// import "./App.css";
import "./css/styles.css"

import Home from "./pages/home";
import Card from "./pages/card"

import Updates from "./container/updates";
import Menubar from "./container/menubar";
import Footer from "./container/footer";
import Eodb from "./pages/eodb";

export default function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Updates />
        {/* <Menubar /> */}
        {/* <Route exact path="/" component={Home}/>         */}
        <Route exact path="/" component={Card}/>
        <Route exact path="/eodb" component={Eodb}/>
        <Footer />

      </div>
    </BrowserRouter>
  );
}
